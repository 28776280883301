import Image from "next/image"
import { useRouter } from "next/router"
import { useState, useEffect } from "react"
import { usePathData } from "@/lib/contexts/appContext"
import Modal from "react-modal"
import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import styles from "./lightbox.module.css"
import Close from "@/components/common/icons/close"
import classNames from "classnames"

export default function Lightbox({ block }) {
  const { path } = usePathData()
  const [isOpen, setOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const { id, image, width } = block.attrs
  const router = useRouter()

  const toggleModal = () => {
    if (window.location.hash) {
      router.replace(path, null, { shallow: true })
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
      })
    }

    setOpen(!isOpen)
  }

  useEffect(() => {
    if (window.location.hash) {
      if (
        !isOpen &&
        window.location.hash.replace("#", "") === id.toLowerCase()
      ) {
        setScrollPosition(window.scrollY)
        setOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const follow = (event) => {
    event.preventDefault()
    router.replace(event.target.getAttribute("href"), null, { shallow: true })
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 && isOpen) {
      setOpen(false)
    }
  }

  useEffect(() => {
    const targets = []

    setTimeout(function () {
      const tags = document.querySelectorAll(
        '*[href*="#' + id.toLowerCase() + '"]'
      )

      for (const tag of tags) {
        targets.push(tag)
        tag.addEventListener("click", follow)
      }
    }, 100)

    window.addEventListener("keyup", handleKeyDown)

    return () => {
      window.removeEventListener("keyup", handleKeyDown)

      if (targets.length > 0) {
        targets.forEach((tag) => {
          tag.removeEventListener("click", follow)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (id?.length === 0) {
    return null
  }

  Modal.setAppElement("#__next")

  const customStyles = {
    overlay: {
      display: "flex",
      backgroundColor: "rgba(0, 0, 0, .5)",
      zIndex: 1000,
      alignItems: "center",
      justifyContent: "center"
    }
  }

  const settings = {
    "core/buttons": { width: "bleed" },
    "core/heading": { width: "bleed" },
    "core/paragraph": { width: "bleed" },
    "core/list": { width: "bleed" }
  }

  return (
    <Modal
      key={id}
      id={"modal-" + id}
      isOpen={isOpen}
      style={customStyles}
      shouldReturnFocusAfterClose={true}
      shouldCloseOnOverlayClick={true}
      className={classNames(styles.modal, { large: width === "large" })}>
      <div className="close-button" onClick={() => toggleModal()}>
        <div className="circle">
          <Close className="icon" />
        </div>
      </div>
      <Block block={block} className="content-wrapper" noStyling={true}>
        {image && (
          <div className="image-wrapper">
            <figure>
              <Image
                className="object-cover object-center w-full h-full"
                src={image.url}
                alt={image.alt ?? ""}
                sizes="(max-width: 768px) 100vw, 50vw"
                layout="fill"
              />
            </figure>
          </div>
        )}
        <div className="inner-blocks">
          <Blocks blocks={block.innerBlocks} settings={settings} />
        </div>
      </Block>
    </Modal>
  )
}
